import React, { useState, useEffect, ReactNode, useRef } from 'react';
import { BrowserRouter, Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Nav from './components/Nav';
import Home from './pages/Home';
import About from './pages/About';
import Photographs from './pages/Photographs';
import Explore from './pages/Explore';
import GSL from './pages/subpages/GSL';
import Espresso from './pages/subpages/Espresso';
import TwoRunner from './pages/subpages/2runner';
import Archive from './pages/subpages/Archive';
import RimowaComparison from './pages/subpages/RimowaComparison';
import OnTravel from './pages/subpages/OnTravel';
import NotFound from './pages/NotFound';
import { setMetaTags, findFirstImage } from './utils/metaTags';

// Meta tag configuration for each route
const routeMetaConfig: Record<string, { title: string; description: string }> = {
  '/': {
    title: 'Home',
    description: 'Designer, startup founder, and creative director living in New York City since 2011.'
  },
  '/about': {
    title: 'About',
    description: 'Designer, startup founder, and creative director. Learn about my experience and background.'
  },
  '/photographs': {
    title: 'Photographs',
    description: 'Photography collection by Taylor Pemberton. Explore my visual work and travels.'
  },
  '/explore': {
    title: 'Explore',
    description: 'Ongoing hobbies, writings, projects, and explorations by Taylor Pemberton.'
  },
  '/explore/gsl': {
    title: 'Great Salt Lake',
    description: 'Exploring the Great Salt Lake - a photo essay by Taylor Pemberton.'
  },
  '/explore/espresso': {
    title: 'Espresso Setup',
    description: 'My home espresso setup and recommendations.'
  },
  '/explore/2runner': {
    title: '2Runner',
    description: 'My 2Runner build and modifications.'
  },
  '/explore/archive': {
    title: 'Archive',
    description: 'Archive of past projects and work by Taylor Pemberton.'
  },
  '/explore/rimowa-comparison': {
    title: 'Rimowa Comparison',
    description: 'Comparing different Rimowa luggage models and options.'
  },
  '/explore/on-travel': {
    title: 'On Travel',
    description: 'Thoughts and tips on travel from Taylor Pemberton.'
  },
  '/404': {
    title: 'Page Not Found',
    description: 'The page you\'re looking for doesn\'t exist. Head back to the home page.'
  }
};

interface PageContentWrapperProps {
    children: ReactNode;
    theme: 'light' | 'dark';
}

const PageContentWrapper: React.FC<PageContentWrapperProps> = ({ children, theme }) => {
    return (
        <div className={`${theme === 'light' ? 'bg-white' : ''}`}>
            {children}
        </div>
    );
};

interface AppContentProps {
    theme: 'light' | 'dark';
    setTheme: (theme: 'light' | 'dark') => void;
}

// Add this function at the top level, outside of any component
function scrollToHashElement() {
  // Check if there's a hash in the URL
  if (window.location.hash) {
    // Get the target element
    const hash = window.location.hash.substring(1);
    const element = document.getElementById(hash);
    
    // If the element exists, scroll to it
    if (element) {
      // Use setTimeout to ensure the DOM is fully loaded
      setTimeout(() => {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - 144;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }, 300);
    }
  }
}

const AppContent: React.FC<AppContentProps> = ({ theme, setTheme }) => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const contentRef = useRef<HTMLDivElement>(null);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    const getPageTheme = (pathname: string): 'light' | 'dark' => {
        if (pathname === '/photographs') return 'light';
        if (pathname === '/explore/gsl') return 'dark';
        if (pathname === '/explore/archive') return 'dark';
        if (pathname === '/explore/rimowa-comparison') return 'dark';
        return theme;
    };

    const themeClasses = theme === 'light' ? 'bg-white text-black' : 'bg-black text-white';
    const currentTheme = getPageTheme(location.pathname);

    // Handle redirects
    useEffect(() => {
        if (location.pathname === '/news') {
            navigate('/', { replace: true });
        }
    }, [location.pathname, navigate]);

    // Handle hash links on page load and route changes
    useEffect(() => {
        scrollToHashElement();
    }, [location.pathname, location.hash]);

    // Handle meta tags
    useEffect(() => {
        // Function to update meta tags based on current route
        const updateMetaTags = () => {
            // Get meta config for current route, or use default if not found
            const path = location.pathname === '/' ? '/' : location.pathname;
            const metaConfig = routeMetaConfig[path] || routeMetaConfig['/'];
            
            // Wait a bit to ensure images are loaded
            setTimeout(() => {
                // Find the first image in the content
                const imageUrl = findFirstImage(contentRef);
                
                // Set meta tags
                setMetaTags({
                    title: metaConfig.title,
                    description: metaConfig.description,
                    imageUrl: imageUrl,
                    url: window.location.href
                });
            }, 500);
        };

        // Update meta tags when route changes
        updateMetaTags();
        
        // Also update when images are loaded
        if (imagesLoaded) {
            updateMetaTags();
        }

        // Add event listener for when all images are loaded
        const handleLoad = () => {
            if (document.readyState === 'complete') {
                setImagesLoaded(true);
            }
        };

        window.addEventListener('load', handleLoad);
        return () => window.removeEventListener('load', handleLoad);
    }, [location.pathname, imagesLoaded]);

    return (
        <div ref={contentRef} className={`min-h-screen ${themeClasses} main-content ${currentTheme === 'dark' ? 'dark-theme-container' : ''}`}>
            {/* Mobile Header */}
            <div className="md:hidden sticky top-0 z-50">
                <div className={`p-4 bg-white ${theme === 'light' ? 'border-white' : 'border-white'}`}>
                    <Link to="/" className={theme === 'light' ? 'text-black' : 'text-black'}>
                        Taylor Pemberton
                    </Link>
                </div>
                
                <div className={theme === 'light' ? 'bg-white bg-opacity-5' : 'bg-black bg-opacity-5'}>
                    <Nav theme={theme} />
                </div>
            </div>

            {/* Desktop Sidebar */}
            <div className={`hidden md:block fixed inset-y-0 left-0 w-[288px] z-10 ${theme === 'light' ? 'bg-white' : 'bg-black'}`}>
                <Sidebar theme={theme} />
            </div>

            {/* Main Content */}
            <main className={`md:pl-[288px] ${themeClasses} relative z-5`}>
                {/* Desktop Nav */}
                <div className="hidden md:block sticky top-0 z-40">
                    <Nav theme={theme} />
                </div>

                {/* Page Content */}
                <div className={`w-full ${currentTheme === 'light' ? 'bg-white' : ''}`}>
                    <div className="px-4 pt-28 -mt-24 md:pt-32 pb-20 max-w-[652px]">
                        <Routes>
                            <Route path="/" element={<Home theme={getPageTheme('/')} />} />
                            <Route path="/about" element={<About theme={getPageTheme('/about')} />} />
                            <Route path="/photographs" element={<Photographs theme={getPageTheme('/photographs')} />} />
                            <Route path="/explore" element={<Explore theme={getPageTheme('/explore')} />} />
                            <Route path="/explore/gsl" element={<GSL theme={getPageTheme('/explore/gsl')} />} />
                            <Route path="/explore/espresso" element={<Espresso theme={getPageTheme('/explore/espresso')} />} />
                            <Route path="/explore/archive" element={<Archive theme={getPageTheme('/explore/archive')} />} />
                            <Route path="/explore/rimowa-comparison" element={<RimowaComparison theme={getPageTheme('/explore/rimowa-comparison')} />} />
                            <Route path="/explore/on-travel" element={<OnTravel theme={getPageTheme('/explore/on-travel')} />} />
                            <Route path="/explore/condo-renovation" element={<GSL theme={getPageTheme('/explore/gsl')} />} />
                            <Route path="/2runner" element={<TwoRunner theme={getPageTheme('/explore/2runner')} />} />
                            <Route path="/news" element={<NewsRedirect />} />
                            <Route path="*" element={<NotFound theme={getPageTheme('/')} />} />
                        </Routes>
                    </div>
                </div>
            </main>
        </div>
    );
};

const NewsRedirect: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/', { replace: true });
    }, [navigate]);

    return <p>Redirecting...</p>;
};

const App: React.FC = () => {
    const [theme, setTheme] = useState<'light' | 'dark'>('dark');

    return (
        <div className="main-content">
            <BrowserRouter>
                <AppContent theme={theme} setTheme={setTheme} />
            </BrowserRouter>
        </div>
    );
};

export default App; 