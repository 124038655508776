import React from 'react'

interface LinkTooltipProps {
    copied: boolean
    onClick: () => void
}

export function LinkTooltip({ copied, onClick }: LinkTooltipProps) {
    return (
        <div className="relative">
            <button 
                onClick={onClick}
                className={`
                    ml-2 opacity-0 group-hover:opacity-100 transition-opacity text-gray-500 hover:text-lime-500 focus:outline-none
                    ${copied ? 'before:content-["URL_copied"] before:absolute before:bottom-6 before:left-1/2 before:-translate-x-1/2 before:bg-lime-500 before:text-gray-900 before:px-3 before:py-1 before:rounded before:whitespace-nowrap before:transition-opacity before:text-[20px]' : ''}
                    ${copied ? 'after:content-[""] after:absolute after:bottom-4 after:left-1/2 after:-translate-x-1/2 after:border-[8px] after:border-transparent after:border-t-lime-500' : ''}
                `}
                aria-label="Copy link to this section"
            >
                {copied ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                )}
            </button>
        </div>
    )
} 