import React, { ReactNode, useState, useEffect } from 'react';
import TimeStamp from './TimeStamp';
import { LinkTooltip } from './LinkTooltip';

interface SectionProps {
    id: string;
    title?: string;
    children: ReactNode;
}

interface SectionImageProps {
    src: string;
    alt: string;
    height?: string;
    width?: string;
    caption?: string | React.ReactNode;
    className?: string;
}

interface SectionHeadingProps {
    title: ReactNode;
    lastSaved?: string;
    id?: string;
}

// Function to convert a string to a URL-friendly slug
function slugify(text: string): string {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')        // Replace spaces with -
        .replace(/[^\w\-]+/g, '')    // Remove all non-word chars
        .replace(/\-\-+/g, '-')      // Replace multiple - with single -
        .replace(/^-+/, '')          // Trim - from start of text
        .replace(/-+$/, '');         // Trim - from end of text
}

const Section: React.FC<SectionProps> = ({ id, title, children }) => {
    return (
        <section id={id} className="mb-16">
            {title && <h2 className="text-sm text-gray-500 mb-1">{title}</h2>}
            {children}
        </section>
    );
};

export const SectionImage: React.FC<SectionImageProps> = ({ 
    src, 
    alt, 
    height = 'auto', 
    width = 'auto', 
    caption,
    className = '' 
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div className="my-6">
            <div className={`relative overflow-hidden aspect-[7/5] ${className}`}>
                {!imageLoaded && (
                    <div className="absolute inset-0 bg-neutral-800 bg-opacity-90 flex items-center justify-center">
                        <span className="text-gray-500">Loading…</span>
                    </div>
                )}
                <img
                    src={src}
                    alt={alt}
                    className="w-full h-full object-cover"
                    loading="lazy"
                    onLoad={() => setImageLoaded(true)}
                />
            </div>
            {caption && (
                <div className="text-gray-500 text-[10pt] mt-1">
                    {caption}
                </div>
            )}
        </div>
    );
};

export const SectionBody: React.FC<{ children: ReactNode }> = ({ children }) => {
    return <div className="space-y-4">{children}</div>;
};

export const SectionBodyText: React.FC<{ children: ReactNode }> = ({ children }) => {
    return <p className="text-white">{children}</p>;
};

export const SectionHeading: React.FC<SectionHeadingProps> = ({ title, lastSaved, id }) => {
    const headingId = id || (typeof title === 'string' ? slugify(title) : id);
    const [copied, setCopied] = useState(false);
    
    // Check if title ends with colon
    const hasColon = React.useMemo(() => {
        if (typeof title === 'string') {
            return title.trim().endsWith(':');
        }
        // For ReactNode titles, check the last text node
        if (React.isValidElement(title)) {
            const element = title as React.ReactElement<{ children?: React.ReactNode }>;
            if (element.props.children) {
                const children = React.Children.toArray(element.props.children);
                const lastChild = children[children.length - 1];
                return typeof lastChild === 'string' && lastChild.trim().endsWith(':');
            }
        }
        return false;
    }, [title]);
    
    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => setCopied(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [copied]);
    
    const copyLink = () => {
        const url = `${window.location.origin}${window.location.pathname}#${headingId}`;
        navigator.clipboard.writeText(url);
        setCopied(true);
    };
    
    return (
        <div className="mb-4 group relative">
            <div id={headingId} className="text-base flex items-center">
                <span>{title}</span>
                {hasColon && <LinkTooltip copied={copied} onClick={copyLink} />}
            </div>
        </div>
    );
};

export default Section; 