import React from 'react';
import Section, { SectionBody, SectionBodyText, SectionHeading } from '../../components/Section';
import BackToTop from '../../components/BackToTop';
import TimeStamp from '../../components/TimeStamp';
import { setMetaTags } from '../../utils/metaTags';

interface GSLProps {
    theme: string;
}

const GSL: React.FC<GSLProps> = ({ theme }) => {
    const mainImage = "/images/gsl-optimized/1.IMG_0678.jpg";
    const baseUrl = window.location.origin;
    const fullImageUrl = `${baseUrl}${mainImage}`;
    const pageTitle = "GSL Loft Renovation - West Loop Chicago";
    const pageDescription = "A complete gut renovation of a loft-space in Chicago's developing West Loop neighborhood. Custom design and build-out with modern amenities.";

    React.useEffect(() => {
        setMetaTags({
            title: pageTitle,
            description: pageDescription,
            imageUrl: fullImageUrl,
            url: `${baseUrl}/explore/condo-renovation`
        });
    }, []);

    return (
        <div>
            <Section id="gsl">
                <SectionBody>
                    <SectionBodyText>
                        In May 2015, I purchased and renovated a loft-space in Chicago's developing neighborhood, West Loop.
                    </SectionBodyText>

                    {/* Hero image - full width */}
                    <div className="mb-4 relative aspect-[4/5] w-full">
                        <img 
                            src={mainImage}
                            alt="GSL Renovation Hero Shot"
                            className="w-full h-full object-cover"
                            loading="eager"
                        />
                    </div>

                    <SectionBodyText>
                        Project summary: My brother and I worked together over 12 months to design, plan, and oversee a complete gut renovation. We collaborated on all aspects of design—first eliminating all existing walls and appliances—and then transforming the space through a custom ground-up build-out. The unit is currently rented but interest for future occupancy is always welcome. <a href="mailto:taylor@taylorpemberton.com" className="text-lime-500 hover:text-lime-400 transition-colors">Email me to get in touch.</a>
                    </SectionBodyText>

                    {/* Side by side comparison */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/2.IMG_0699-1.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/3.IMG_0699-2.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                    </div>

                    {/* Full width dramatic shot */}
                    <div className="mb-4">
                        <img 
                            src="/images/gsl-optimized/4.IMG_0699-3.jpg"
                            alt="GSL Renovation"
                            className="w-full h-auto"
                            loading="eager"
                        />
                    </div>

                    {/* Side by side */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/5.IMG_0733.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/6.IMG_0740.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                    </div>

                    {/* Side by side */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/7.IMG_0750.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/8.IMG_0759.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                    </div>

                    {/* Side by side */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/9.IMG_0765.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/10.IMG_0775.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                    </div>

                    {/* Side by side */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/11.IMG_0793.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/12.IMG_0811.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                    </div>

                    {/* Full width feature */}
                    <div className="mb-4">
                        <img 
                            src="/images/gsl-optimized/13.IMG_0829.jpg"
                            alt="GSL Renovation"
                            className="w-full h-auto"
                            loading="eager"
                        />
                    </div>

                    {/* Side by side */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/14.IMG_0835.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/15.IMG_0844.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                    </div>

                    {/* Side by side */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/16.IMG_0863-2.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/17.IMG_0916-1 (1).jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                    </div>

                    {/* Side by side */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/18.IMG_0916-3 (1).jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/19.IMG_0916-4.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                    </div>

                    {/* Side by side */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/20.IMG_0952.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                        <div className="relative aspect-[4/5]">
                            <img 
                                src="/images/gsl-optimized/21.IMG_0964.jpg"
                                alt="GSL Renovation"
                                className="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </div>
                    </div>

                    {/* Full width dramatic shots */}
                    <div className="space-y-4 mb-4">
                        <img 
                            src="/images/gsl-optimized/22.IMG_0978.jpg"
                            alt="GSL Renovation"
                            className="w-full h-auto"
                            loading="eager"
                        />
                        <img 
                            src="/images/gsl-optimized/23.IMG_1023-3.jpg"
                            alt="GSL Renovation"
                            className="w-full h-auto"
                            loading="eager"
                        />
                    </div>

                    <div className="text-gray-300">
                        <h2 className="text-lime-500 font-normal mb-2">Amenities:</h2>
                        <ul className="list-disc list-inside">
                            <li>1 block from the UIC-Halsted Blue Line Stop</li>
                            <li>4 blocks from Whole Foods and Mariano's</li>
                            <li>6 blocks from Soho House and Randolph</li>
                            <li>Custom plywood base-cabinets/shelving</li>
                            <li>Bosch appliances and Kohler plumbing</li>
                            <li>8 large factory windows</li>
                            <li>Dimmable lights</li>
                            <li>Laundry in-unit</li>
                        </ul>
                    </div>

                    <SectionBodyText>
                        Questions? Email <a href="mailto:taylor@taylorpemberton.com" className="text-lime-500 hover:text-lime-400 transition-colors">taylor@taylorpemberton.com</a>
                    </SectionBodyText>
                </SectionBody>
            </Section>

            <BackToTop theme={theme} />
        </div>
    );
};

export default GSL; 