/**
 * Utility to set dynamic meta tags for social media sharing
 */

interface MetaTagsOptions {
  title?: string
  description?: string
  imageUrl?: string
  url?: string
}

/**
 * Sets dynamic meta tags for social media sharing
 * This function updates Open Graph and Twitter card meta tags
 */
export function setMetaTags({
  title = "Taylor Pemberton",
  description = "Designer, startup founder, and creative director based in New York City.",
  imageUrl,
  url = window.location.href
}: MetaTagsOptions): void {
  // Set document title
  document.title = title ? `${title} | Taylor Pemberton` : "Taylor Pemberton - Designer & Founder"
  
  // Update meta tags
  updateMetaTag("description", description)
  updateMetaTag("title", title)
  
  // Open Graph tags
  updateMetaTag("og:site_name", "Taylor Pemberton")
  updateMetaTag("og:title", title)
  updateMetaTag("og:description", description)
  updateMetaTag("og:url", url)
  updateMetaTag("og:type", "website")
  
  // Only set image if one is provided - otherwise remove any existing og:image
  if (imageUrl) {
    const absoluteImageUrl = new URL(imageUrl, window.location.origin).href
    updateMetaTag("og:image", absoluteImageUrl)
    updateMetaTag("og:image:secure_url", absoluteImageUrl)
    updateMetaTag("og:image:width", "2000")
    updateMetaTag("og:image:height", "2500")
    updateMetaTag("og:image:type", imageUrl.endsWith('.png') ? "image/png" : "image/jpeg")
    updateMetaTag("og:image:alt", title)
  } else {
    // Remove existing og:image if no image is provided
    removeMetaTag("og:image")
    removeMetaTag("og:image:secure_url")
    removeMetaTag("og:image:width")
    removeMetaTag("og:image:height")
    removeMetaTag("og:image:type")
    removeMetaTag("og:image:alt")
  }
  
  // Twitter tags
  updateMetaTag("twitter:site", "@taylorp")
  updateMetaTag("twitter:creator", "@taylorp")
  updateMetaTag("twitter:title", title)
  updateMetaTag("twitter:description", description)
  
  // Only set Twitter image if one is provided - otherwise remove it
  if (imageUrl) {
    const absoluteImageUrl = new URL(imageUrl, window.location.origin).href
    updateMetaTag("twitter:image", absoluteImageUrl)
    updateMetaTag("twitter:image:alt", title)
    updateMetaTag("twitter:card", "summary_large_image")
  } else {
    removeMetaTag("twitter:image")
    removeMetaTag("twitter:image:alt")
    updateMetaTag("twitter:card", "summary")
  }
}

/**
 * Helper function to find the first image in a component
 * @param containerRef React ref to the container element
 * @returns URL of the first image found or undefined
 */
export function findFirstImage(containerRef: React.RefObject<HTMLElement | HTMLDivElement | null>): string | undefined {
  if (!containerRef.current) return undefined
  
  // Find all images in the container
  const imgElements = containerRef.current.querySelectorAll('img')
  
  // Return the first image with a valid src that isn't a tiny icon or logo
  for (let i = 0; i < imgElements.length; i++) {
    const img = imgElements[i]
    if (img.src && 
        !img.src.includes('favicon') && 
        !img.src.includes('icon') && 
        !img.classList.contains('logo') &&
        img.width > 100 && 
        img.height > 100) {
      
      // Make sure we return an absolute URL
      return new URL(img.src, window.location.origin).href
    }
  }
  
  return undefined
}

/**
 * Helper function to update a meta tag
 */
function updateMetaTag(name: string, content: string): void {
  // First try to find by property attribute (used by og: tags)
  let metaTag = document.querySelector(`meta[property="${name}"]`)
  
  // If not found, try by name attribute (used by description, twitter: tags)
  if (!metaTag) {
    metaTag = document.querySelector(`meta[name="${name}"]`)
  }
  
  if (metaTag) {
    // Update existing tag
    metaTag.setAttribute("content", content)
  } else {
    // Create new tag if it doesn't exist
    const newMeta = document.createElement("meta")
    if (name.startsWith("og:")) {
      newMeta.setAttribute("property", name)
    } else {
      newMeta.setAttribute("name", name)
    }
    newMeta.setAttribute("content", content)
    document.head.appendChild(newMeta)
  }
}

/**
 * Helper function to remove a meta tag
 */
function removeMetaTag(name: string): void {
  // Try to find by property attribute (used by og: tags)
  let metaTag = document.querySelector(`meta[property="${name}"]`)
  
  // If not found, try by name attribute (used by description, twitter: tags)
  if (!metaTag) {
    metaTag = document.querySelector(`meta[name="${name}"]`)
  }
  
  // Remove the tag if found
  if (metaTag && metaTag.parentNode) {
    metaTag.parentNode.removeChild(metaTag)
  }
} 